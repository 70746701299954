import React, { useState } from "react";
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap';


const LoginForm = ({ onSubmit }) => {

    return (
        <div className="_button-wrapper _full_width">

            <p className="text-center">
                Masukan nama lengkap dan email aktif kamu <br/> untuk dapatkan penawaran spesial.
            </p>

            <Form method="POST" action="https://mrjose.activehosted.com/proc.php">
            <input type="hidden" name="u" value="77" />
            <input type="hidden" name="f" value="77" />
            <input type="hidden" name="s" />
            <input type="hidden" name="c" value="0" />
            <input type="hidden" name="m" value="0" />
            <input type="hidden" name="act" value="sub" />
            <input type="hidden" name="v" value="2" />

            <Form.Group controlId="formBasicPassword">
                <Form.Label>Nama Lengkap : </Form.Label>
                <Form.Control type="text" name="fullname" placeholder="Wajib" required/>
            </Form.Group>

            <Form.Group controlId="formBasicEmail">
                <Form.Label>Email Aktif:</Form.Label>
                <Form.Control type="email" name="email" placeholder="Wajib" required/>
            </Form.Group>

            <Button className="__color-outline" type="submit" size="lg" block >
                Submit
            </Button>
            </Form>

        </div>
    );
};


export default function IndexPage(){

    const [show, setShow] = useState(false);
  
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
    const onLoginFormSubmit = (e) => {
      e.preventDefault();
      handleClose();
    };
    return (
        <>
        
             <Container fluid className="__bg-primary-sl-expired">
                <Container>
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__image_logo-sl">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/logo-am-love.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"
                                    width="250px"
                                    height="250px"/>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__introduction">
                                <h1 className="text-center text-white text-expired">
                                    ATTRACTION MASTERY
                                </h1>
                                <p className="text-center text-white text-expired-p">Penawaran Attraction Mastery sudah berakhir agar kamu tidak melawatkan <br/> kesempatan ini lagi Yuk Join Waiting List dengan klik button  <br/> di bawah ini dan dapatkan penawaran spesial.</p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__text-desc">    
                                <div className="d-flex justify-content-center __flex-mobile">
                                    <Button variant="primary" size="lg" onClick={handleShow}>
                                        JOIN WAITING LIST
                                    </Button>{' '}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <Modal 
            
            show={show} onHide={handleClose} 
            size="md"
            centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="__text-wl-title">
                        Join Waiting List
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="__modal-body-bg">
                    <LoginForm onSubmit={onLoginFormSubmit} />
                </Modal.Body>
                
            </Modal>
           

        </>
    )
}

